import React, {
    useState, useContext, useEffect,
    useRef,
} from 'react';
import {
    Box, DialogTitle, MenuItem,
    Dialog, Button, DialogActions, DialogContent,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddOrganisationModalStyle from './ProjectAssignmentModal.style';
import Loader from '../../../../components/Loader';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
// import AgGridTable from '../../../../components/agGridTableComponent';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import GetOrgUser from '../../../../types/GetOrgUser';
import orgQueries from '../../../../queries/organisations';
import projectQuery from '../../../../queries/projects';
import { UserContext } from '../../../../store/context/userContext';
import NotificationBarType from '../../../../types/NotificationBarType';
import NotificationBar from '../../../../components/NotificationBar';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { formatCurr } from '../../../../hooks/useFormatCurrency';
import { OrgUserCellRendererType } from '../../../../types/OrgUserProjects';
import {
    IRowNode, SelectionChangedEvent, GridApi, ColumnRowGroupChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import RowType from '../../../../types/GetProjectAssignedType';
import AgGridComponent from '../../../../components/agGridTableComponent';

interface ProjectAssignmentModalProps {
    open: boolean,
    setOpenAddOrganisationModal: (isOpen: boolean) => void,
    selectedUser: GetOrgUser,
}
interface CellRendererType {
    colDef: {
        field: string
    }
    value: string,
    data : {
        id : string,
        permission_name: string,
    }
    // To update the change of permission (e.g,, None to View) in all selected nodes GridApi is required
    api?: GridApi
}

function ProjectAssignmentModal({
    open, setOpenAddOrganisationModal, selectedUser,
}: ProjectAssignmentModalProps) {
    const classes = AddOrganisationModalStyle();
    const userContext = useContext(UserContext);
    const orgID = userContext?.user?.default_org_id;
    const [tableData, setTableData] = useState<RowType[]>([]);
    const [changedTabledData, setChangedTabledData] = useState<RowType[]>([]);
    // const [tableInitialData, setTableInitialData] = useState<RowType[]>([]);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [searchText, setSearchText] = useState<string>('');
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const gridRef = useRef<AgGridReact<RowType>>(null);
    const cont: OutletContext = useOutletContext();
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    /** Updates the previous table data with new table data by modifying the permission ID and name.
    *
    * @param id - The ID of the row to update.
    * @param permissionChangeName - The new permission name.
    * @param permissionChangeID - The new permission ID.
    * @param prevState - The previous state of changed table data.
    * @param tempItem - The temporary item to add if not already present.
    *
    * @returns newState - The updated changed table data.
    */
    const updateChangedTableData = (
        id: string,
        permissionChangeName: string,
        permissionChangeID: number,
        prevState: RowType[],
        tempItem: RowType,
    ) => {
        const newState = [...prevState];

        const existingIndex = newState.findIndex((pData) => pData.id === id);

        // if data is present already, tableData[existingIndex] will be updated or will be pushed as new data
        if (existingIndex >= 0) {
            newState[existingIndex] = {
                ...newState[existingIndex],
                permission_id: permissionChangeID,
                permission_name: permissionChangeName,
            };
        } else {
            newState.push(tempItem);
        }

        return newState;
    };

    const updateTableData = (id: string, permissionChangeName: string, permissionChangeID: number) => {
        // const tempData = [...tableData];
        // const tempDataArr: RowType[] = [];

        // tempData.forEach((item) => {
        //     if (item.id === id) {
        //         const tempItem:RowType = {
        //             id: item.id,
        //             country_name: item.country_name,
        //             region: item.region,
        //             name: item.name,
        //             title: 'Title',
        //             owner: item.owner,
        //             consultant: item.consultant,
        //             total_cost: item.total_cost,
        //             year: item.year,
        //             version_code: item.version_code,
        //             permission_name: permissionChangeName,
        //             permission_id: permissionChangeID,
        //             currency_data: item.currency_data,
        //         };
        //         tempDataArr.push(tempItem);
        //         // When a project assingment is changed add it to changedTableData.
        //         setChangedTabledData((prevState) => {
        //             const newState = [...prevState];
        //             const isPresent = prevState.findIndex((pData) => pData.id === id);
        //             if (isPresent >= 0) {
        //                 newState[isPresent].permission_id = permissionChangeID;
        //                 newState[isPresent].permission_name = permissionChangeName;
        //             } else {
        //                 newState.push(tempItem);
        //             }
        //             return newState;
        //         });
        //     } else {
        //         tempDataArr.push(item);
        //     }
        // });
        // setTableData(tempDataArr);
        setTableData((prevTableData) => {
            const updatedData = prevTableData.map((item) => {
                if (item.id === id) {
                    const tempItem:RowType = {
                        id: item.id,
                        country_name: item.country_name,
                        region: item.region,
                        name: item.name,
                        title: 'Title',
                        owner: item.owner,
                        consultant: item.consultant,
                        total_cost: item.total_cost,
                        year: item.year,
                        version_code: item.version_code,
                        permission_name: permissionChangeName,
                        permission_id: permissionChangeID,
                        currency_data: item.currency_data,
                    };
                    const updatedItem = {
                        ...item,
                        permission_name: permissionChangeName,
                        permission_id: permissionChangeID,
                    };

                    setChangedTabledData((prevState) => updateChangedTableData(
                        id,
                        permissionChangeName,
                        permissionChangeID,
                        prevState,
                        tempItem,
                    ));

                    return updatedItem;
                }
                return item;
            });

            return updatedData;
        });
    };

    // eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition
    const CustomSelectRenderer = (params: CellRendererType) => {
        const [menuData, setMenuData] = useState(params?.data?.permission_name);
        const handleChange = (event: SelectChangeEvent) => {
            setMenuData(event.target.value);
            let permissionChangeID :number;
            let permissionChangeName: string;
            if (event.target.value === 'Edit') {
                permissionChangeID = 2;
                permissionChangeName = 'Edit';
            } else if (event.target.value === 'View') {
                permissionChangeID = 1;
                permissionChangeName = 'View';
            } else {
                permissionChangeID = 0;
                permissionChangeName = 'None';
            }
            updateTableData(params?.data.id, permissionChangeName, permissionChangeID);

            // Only selected Nodes will update for permission name and id
            (params?.api?.getSelectedNodes() as IRowNode[]).forEach((node) => {
                const rowData = { ...node.data } as RowType;
                if (selectedRowIds.includes(params?.data.id)) {
                    rowData.permission_name = permissionChangeName;
                    rowData.permission_id = permissionChangeID;
                    updateTableData(rowData.id, permissionChangeName, permissionChangeID);
                }
            });
        };

        const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
            const value = event.currentTarget.getAttribute('data-value') || '';
            if (menuData !== value) {
                // Only update if the value is different
                setMenuData(value);
            } else {
                // Manually trigger a change if the same item is selected
                handleChange({
                    target: { value },
                } as SelectChangeEvent);
            }
        };
        return (
            <div className={classes.selectBoxStyle}>
                <Select
                    value={menuData}
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                '& .MuiMenuItem-root': {
                                    color: '#787878',
                                },
                                '& .Mui-selected': {
                                    color: '#021948',
                                    bgcolor: '#E6ECFA !important',
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="None" data-value="None" onClick={handleMenuItemClick}>None</MenuItem>
                    <MenuItem value="View" data-value="View" onClick={handleMenuItemClick}>View</MenuItem>
                    <MenuItem value="Edit" data-value="Edit" onClick={handleMenuItemClick}>Edit</MenuItem>
                </Select>
            </div>
        );
    };

    const customCellRenderer = (params: OrgUserCellRendererType) => {
        const colName = params?.colDef?.field;
        if (params.value !== null) {
            switch (colName) {
            case 'total_cost':
                return `${params?.data?.currency_data?.symbol || '$'}${formatCurr(+params.value.toFixed(0), 'en-US')}`;
            default:
                return params?.value;
            }
        } else if (params?.value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'country_name', type: 'string', initialWidth: 120, headerName: 'Country',
        },
        {
            field: 'region', type: 'string', initialWidth: 120, headerName: 'Region',
        },
        {
            field: 'name', type: 'string', initialWidth: 300, headerName: 'Name',
        },
        {
            field: 'title', type: 'string', initialWidth: 130, headerName: 'Title',
        },
        {
            field: 'owner', type: 'string', initialWidth: 130, headerName: 'Owner',
        },
        {
            field: 'consultant', type: 'string', initialWidth: 140, headerName: 'Consultant',
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            initialWidth: 180,
            headerName: 'Total',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'year', type: 'string', initialWidth: 110, headerName: 'Year',
        },
        {
            field: 'version_code', type: 'number', initialWidth: 110, headerName: 'Version',
        },
        {
            field: 'permission_name',
            type: 'number',
            initialWidth: 150,
            headerName: 'Permission',
            cellRendererFramework: CustomSelectRenderer,
            pinned: 'right',
        },
    ];

    const handleClose = () => {
        setOpenAddOrganisationModal(false);
        setTableData([]);
        // setTableInitialData([]);
        setChangedTabledData([]);
        setSelectedRowIds([]);
    };

    const changePermissionDone = () => {
        setNotificationDetails({
            open: true,
            message: 'Permission changed successfully.',
            type: 'success',
        });
        handleClose();
    };
    const { data: sucessFullyConfig, isLoading, mutate: changeUserProjectPermission } = useGQLMutation(
        'changeProjectPermissions',
        projectQuery.PROJECTS_ASSIGNMENTS_FOR_USER,
        {
            enabled: !!(orgID && selectedUser?.user?.id),
            onSuccess: changePermissionDone,
        },
        '/setting',
    );

    const { data, isFetching, refetch } = useGQLQuery(
        `GetOrganisationProjects-${selectedUser.user.id}`,
        orgQueries.GET_ORGANISATIONS_USER_PROJECTS(0, DEFAULT_PAGE_SIZE, orgID, selectedUser.user.id),
        {},
        {
            enabled: !!(open),
            onError: handleApiError,
        },
        '/setting',
    );

    useEffect(() => {
        if (open) {
            refetch();
        }
    }, [orgID, selectedUser.user.id, refetch]);

    const orgUserProjects = data?.getorganduserprojects;
    const orguserprojectstableData = orgUserProjects?.data;
    // const pageInfo = orgUserProjects?.pageInfo;

    const updateTableState = () => {
        if (orguserprojectstableData && !isFetching) {
            const tempData = orguserprojectstableData.map((item) => {
                let permission;
                if (item.permission_id === 0) {
                    permission = 'None';
                } else if (item.permission_id === 1) {
                    permission = 'View';
                } else {
                    permission = 'Edit';
                }
                return {
                    id: item.id,
                    country_name: item.country_name,
                    region: item.region,
                    name: item.name,
                    title: 'Title',
                    owner: item.owner,
                    consultant: item.consultant,
                    total_cost: item.total_cost,
                    year: new Date(item.base_date).getFullYear(),
                    version_code: item.version_code,
                    permission_name: permission,
                    permission_id: item.permission_id,
                    currency_data: item.currency_data,
                };
            });
            //  setTableInitialData((prevState) => {
            //     const newTableData = [...prevState];
            //     tempData.forEach((apiData) => {
            //         const existingRow = prevState.find((pdata) => pdata.id === apiData.id);
            //         if (!existingRow) {
            //             newTableData.push(apiData);
            //         }
            //     });
            //     return newTableData;
            // });
            setTableData(tempData);
        }
    };

    useEffect(() => {
        if (open) {
            updateTableState();
        }
    }, [orgID, open, isFetching]);

    // const changeDiff = () => {
    //     const diff: { permissionId: number, projectId: string }[] = [];
    //     changedTabledData.forEach((cData) => {
    //         const initialData = tableInitialData.find((tiData) => cData.id === tiData.id);
    //         if (initialData && (cData.permission_name !== initialData.permission_name)) {
    //             diff.push({
    //                 permissionId: cData.permission_id,
    //                 projectId: cData.id,
    //             });
    //         }
    //     });
    //     return diff;
    // };

    const handleSubmit = () => {
        const changeData = changedTabledData.map(({ id, permission_id }) => ({ projectId: id, permissionId: permission_id }));
        if (changeData.length) {
            changeUserProjectPermission({
                orgId: orgID,
                userId: selectedUser.user.id,
                project_assignment: changeData,
            });
        }
    };

    // const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const isSaveButtonDisabled = !changedTabledData.length;

    // check if previously value was changed
    // const newTableData = tableData.map((tData) => {
    //     const existingRow = changedTabledData.find((cData) => cData.id === tData.id);
    //     return existingRow || tData;
    // });

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        if (gridRef.current) {
            const { api } = gridRef.current;
            api.deselectAll();

            // All selected nodes will get selected again when permission changes.
            api.forEachNode((node) => {
                const matchedItem = selectedRowIds.includes(node?.data?.id as string);
                if (matchedItem) {
                    node.setSelected(true);
                }
            });
        }
    }, [changedTabledData]);

    const onSelectionChanged = (params: SelectionChangedEvent) => {
        const selectedRows = params.api.getSelectedRows() as RowType[];
        setSelectedRowIds(selectedRows.map((row) => row.id));
    };

    // const [noRowSelection, setNoRowSelection] = useState(false);

    // const onColumnRowGroupChanges = (event: ColumnRowGroupChangedEvent) => {
    //     //  When grouping happens No rows will be selected
    //     if (event.columns?.length) {
    //         setNoRowSelection(true);
    //     } else {
    //         setNoRowSelection(false);
    //     }
    // };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>
                    Edit Project Assignments for
                    {' '}
                    {selectedUser.user.user_name}
                    <Box className={classes.inputBoxStyle}>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Quick Filter..."
                            value={searchText}
                            onChange={onFilterTextBoxChanged}
                        />
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Box style={{ minHeight: '250px' }}>
                        {isFetching ? (
                            <Loader loading={isFetching} />
                        ) : (
                            <Box className={classes.tableStyles}>
                                <AgGridComponent
                                    projectAssignmentPopupCustomSelection
                                    // noRowSelection={noRowSelection}
                                    // noSelection
                                    gridRef={gridRef}
                                    columnDefs={columnDefs}
                                    rowData={tableData}
                                    changeSortingValue={() => { }}
                                    rowHeight={44}
                                    headerHeight={31}
                                    isClipboardEnabled
                                    quickFilterText={searchText}
                                    rowSelection="multiple"
                                    onSelectionChanged={onSelectionChanged}
                                    // isToolPanelsEnabled
                                    isPinnable
                                    // onColumnRowGroupChanges={onColumnRowGroupChanges}
                                    moduleName="projAssignment"
                                    disableResizable
                                    suppressScrollOnNewData
                                    setSearchText={setSearchText}
                                />
                                <Loader loading={isLoading} />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Loader loading={false} />
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSaveButtonDisabled}>
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </Box>
    );
}

export default ProjectAssignmentModal;
